import * as React from "react"

import LayoutWhite from "../components/layoutWhite"

const MediosDePagoPage = () => (
  <LayoutWhite
	seccion="mediosDePago"	
	title="Medios de Pago"	
	linkTo="experienciaContainer"
	>		
			<div id="whiteContainer" className="row">
				<div className="col-12"
						data-aos='fade-up'
						data-aos-duration="600" data-aos-once="true">
					<h1 className="">Medios de Pago</h1>
					<ul>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Contado.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cuenta corriente.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Pago on-line a través de Mercado Pago (todos los medios de pago).</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Pagos con tarjetas de crédito.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Transferencias Bancarias.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cobranzas personalizadas.</li>
					</ul>
				</div>			
			</div>
  </LayoutWhite>
)

export default MediosDePagoPage